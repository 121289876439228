import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Carousel, Button } from 'antd';
import './styles.css';

function Home() {
  const navigate = useNavigate();

  return (
    <div className="container">
      <Carousel effect="fade" autoplay className="carousel" dots={false} autoplaySpeed={2000}>
        
      <Card bordered={false} className="language-card">
        <h1>Welcome to Yuantsy Service Platform.</h1>
      </Card>

      <Card bordered={false} className="language-card">
        <h1>欢迎访问源子服务平台.</h1>
      </Card>

      <Card bordered={false} className="language-card">
        <h1>Bienvenue sur la plateforme de services Yuantsy.</h1>
      </Card>

      <Card bordered={false} className="language-card">
        <h1>Bienvenido a la plataforma de servicios Yuantsy.</h1>
      </Card>

      <Card bordered={false} className="language-card">
        <h1>歡迎訪問源子服務平台.</h1>
      </Card>


      <Card bordered={false} className="language-card">
        <h1>ユアンツィサービスプラットフォームへようこそ。</h1>
      </Card>

      <Card bordered={false} className="language-card">
        <h1>유안츠 서비스 플랫폼에 오신 것을 환영합니다.</h1>
      </Card>



      </Carousel>

      <div className="auth-buttons">
        <Button type="primary" ghost onClick={() => navigate("/login")}>Login 登陆</Button>
        <Button type="primary" ghost onClick={() => navigate("/signup")}>SignUp 注册</Button>
      </div>
    </div>
  );
}

export default Home;
