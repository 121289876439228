import React, { useState, useEffect, useCallback } from 'react';
import {
  Layout,
  Row,
  Col,
  Card,
  Table,
  Button,
  notification
} from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

// ---------------------------------------------------------
// 1. Translations in English (en) and Chinese (zh)
// ---------------------------------------------------------
const translations = {
  en: {
    ordersTitle: 'Order List',
    orderNo: 'Order #',
    productServiceName: 'Product / Service Name',
    orderStatus: 'Order Status',
    notPaid: 'Not Paid',
    paid: 'Paid',
    totalAmount: 'Total Amount',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    backToMain: 'Back to Dashboard',
    loginExpired: 'Login has expired',
    pleaseLoginAgain: 'Please log in again.',
    languageBtn: '中文',
    noData: 'No orders found.'
  },
  zh: {
    ordersTitle: '订单列表',
    orderNo: '订单号',
    productServiceName: '产品或服务名称',
    orderStatus: '订单状态',
    notPaid: '未付款',
    paid: '已付款',
    totalAmount: '总金额',
    createdAt: '创建时间',
    updatedAt: '更新时间',
    backToMain: '返回主页',
    loginExpired: '登录已过期',
    pleaseLoginAgain: '请重新登录.',
    languageBtn: 'English',
    noData: '没有订单'
  }
};

const Orders = () => {
  const navigate = useNavigate();

  // ---------------------------------------------------------
  // 2. Language State & Translation Function (memoized)
  // ---------------------------------------------------------
  const [lang, setLang] = useState('en');
  const t = useCallback(
    (key) => translations[lang][key],
    [lang] // Only re-create `t` when `lang` changes
  );

  const toggleLanguage = () => {
    setLang((prev) => (prev === 'en' ? 'zh' : 'en'));
  };

  // ---------------------------------------------------------
  // 3. Data & Pagination States
  // ---------------------------------------------------------
  // `allData` holds the entire list of orders (fetched once).
  // `data` is the current page slice of `allData`.
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Client-side pagination state
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  // ---------------------------------------------------------
  // 4. Columns for the AntD Table
  // ---------------------------------------------------------
  const columns = [
    {
      title: t('orderNo'),
      dataIndex: 'out_trade_no',
      key: 'out_trade_no'
    },
    {
      title: t('productServiceName'),
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: t('orderStatus'),
      dataIndex: 'order_status',
      key: 'order_status',
      render: (status) => (status === 0 ? t('notPaid') : t('paid'))
    },
    {
      title: t('totalAmount'),
      dataIndex: 'total_amount',
      key: 'total_amount'
    },
    {
      title: t('createdAt'),
      dataIndex: 'created_at',
      key: 'created_at'
    },
    {
      title: t('updatedAt'),
      dataIndex: 'updated_at',
      key: 'updated_at'
    }
  ];

  // ---------------------------------------------------------
  // 5. Fetch All Orders (once on mount)
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchAllOrders = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${process.env.REACT_APP_API_ROOT_URL}/payment/orders/`, // No page param
          { headers: { Authorization: `Token ${token}` } }
        );

        // Save the entire list in allData
        const results = response.data.results || [];
        setAllData(results);

        // Initialize pagination total
        setPagination((prev) => ({
          ...prev,
          total: results.length
        }));

        // Show the first page
        const startIdx = 0;
        const endIdx = pagination.pageSize; // 10 by default
        setData(results.slice(startIdx, endIdx));
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response && error.response.status === 401) {
          notification.error({
            message: t('loginExpired'),
            description: t('pleaseLoginAgain')
          });
          navigate('/login');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchAllOrders();
  }, [navigate, t, pagination.pageSize]); 
  // ^ We include pagination.pageSize if you want to fetch the first page 
  //   with the correct size after user changes the size (optional).

  // ---------------------------------------------------------
  // 6. Handle Table Pagination (Client Side)
  // ---------------------------------------------------------
  const handleTableChange = (newPagination) => {
    // newPagination.current, newPagination.pageSize
    const currentPage = newPagination.current;
    const pageSize = newPagination.pageSize;

    const startIdx = (currentPage - 1) * pageSize;
    const endIdx = startIdx + pageSize;

    // Update pagination
    setPagination((prev) => ({
      ...prev,
      current: currentPage,
      pageSize: pageSize
    }));

    // Slice the data for the new page
    setData(allData.slice(startIdx, endIdx));
  };

  // ---------------------------------------------------------
  // 7. Return JSX
  // ---------------------------------------------------------
  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* HEADER */}
      <Header style={{ background: '#001529', padding: '0 20px' }}>
        <Row justify="space-between" align="middle" style={{ height: '64px' }}>
          <Col>
            <h2 style={{ color: '#fff', margin: 0 }}>{t('ordersTitle')}</h2>
          </Col>
          <Col>
            <Button type="primary" onClick={toggleLanguage}>
              {translations[lang].languageBtn}
            </Button>
          </Col>
        </Row>
      </Header>

      {/* CONTENT */}
      <Layout style={{ padding: '16px' }}>
        <Content style={{ backgroundColor: '#fff', padding: '16px' }}>
          <Card bordered={false} style={{ marginBottom: '16px' }}>
            <Button type="primary" onClick={() => navigate('/dashboard')}>
              {t('backToMain')}
            </Button>
          </Card>

          {/* AntD Table with Client-Side Pagination */}
          <Table
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={data}
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: pagination.total,
              showSizeChanger: true,
              showTotal: (total) => `${t('ordersTitle')}: ${total || 0}`
            }}
            loading={loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }} // horizontal scroll for mobile
            locale={{
              emptyText: t('noData')
            }}
          />
        </Content>
      </Layout>

      {/* FOOTER */}
      <Footer style={{ textAlign: 'center' }}>
        © 2025 Yuantsy Group. All Rights Reserved.
      </Footer>
    </Layout>
  );
};

export default Orders;
