import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Select,
  Row,
  Col,
  Layout,
  Typography,
  Card,
  notification
} from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;

// Translations for English (en) and Chinese (zh):
const translations = {
  en: {
    signupTitle: 'Sign Up',
    usernameLabel: 'Username',
    phoneLabel: 'Phone',
    smsLabel: 'Verification Code',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Confirm Password',
    signupBtn: 'Sign Up',
    haveAccountBtn: 'Log In',
    passwordMismatch: 'Passwords do not match',
    sendCodeBtn: 'Send Code',
    registrationFailed: 'Registration Failed',
    languageBtn: '中文'
  },
  zh: {
    signupTitle: '注册',
    usernameLabel: '用户名',
    phoneLabel: '手机号',
    smsLabel: '验证码',
    passwordLabel: '密码',
    confirmPasswordLabel: '确认密码',
    signupBtn: '注册',
    haveAccountBtn: '去登录',
    passwordMismatch: '两次输入的密码不一致',
    sendCodeBtn: '发送验证码',
    registrationFailed: '用户注册失败',
    languageBtn: 'English'
  }
};

const SignupForm = () => {
  const [lang, setLang] = useState('en'); // language state
  
  const [formData, setFormData] = useState({
    username: '',
    phone: '',
    prefix: '+86',
    verificationCode: '',
    password: '',
    passwordConfirm: ''
  });
  const [errorMsg, setErrorMsg] = useState(null);

  // SMS code button states
  const [disableCodeBtn, setDisableCodeBtn] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const navigate = useNavigate();

  // Translation helper
  const t = (key) => translations[lang][key];

  // Countdown effect for the "Send Code" button
  useEffect(() => {
    let timer;
    if (disableCodeBtn) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setDisableCodeBtn(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [disableCodeBtn]);

  // Toggle language
  const toggleLanguage = () => {
    setLang((prev) => (prev === 'en' ? 'zh' : 'en'));
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSignup = () => {
    if (formData.password !== formData.passwordConfirm) {
      setErrorMsg(t('passwordMismatch'));
      return;
    }

    const signupData = {
      username: formData.username,
      phone_number: formData.prefix + formData.phone,
      verificationCode: formData.verificationCode,
      password: formData.password
    };

    axios
      .post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/signup/`, signupData)
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          navigate('/dashboard');
        }
      })
      .catch(() => {
        setErrorMsg(t('registrationFailed'));
      });
  };

  const handleSendCode = () => {
    const phone_number = formData.prefix + formData.phone;

    axios
      .post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/sendcode/`, {
        phone_number
      })
      .then((res) => {
        if (res.status === 200) {
          setDisableCodeBtn(true);
        }
      })
      .catch((err) => {
        notification.error({
          message: t('registrationFailed'),
          description: err?.response?.data?.detail || 'Failed to send code'
        });
      });
  };

  const handleNavigateToLogin = () => {
    navigate('/login');
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <Content>
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: '100vh', padding: '20px' }}
        >
          <Col xs={24} sm={16} md={12} lg={8}>
            <Card
              title={
                <Row justify="space-between" align="middle">
                  <Title level={3} style={{ margin: 0 }}>
                    {t('signupTitle')}
                  </Title>
                  <Button type="link" onClick={toggleLanguage}>
                    {t('languageBtn')}
                  </Button>
                </Row>
              }
            >
              {errorMsg && (
                <Alert
                  message={errorMsg}
                  type="error"
                  style={{ marginBottom: '16px' }}
                />
              )}

              <Form layout="vertical" style={{ marginTop: 8 }}>
                <Form.Item
                  label={t('usernameLabel')}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: `${t('usernameLabel')} is required`
                    }
                  ]}
                >
                  <Input
                    name="username"
                    onChange={handleChange}
                    value={formData.username}
                  />
                </Form.Item>

                <Form.Item
                  label={t('phoneLabel')}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: `${t('phoneLabel')} is required`
                    }
                  ]}
                >
                  <Row gutter={8}>
                    <Col span={8}>
                      <Select
                        defaultValue="+86"
                        style={{ width: '100%' }}
                        onChange={(value) =>
                          setFormData({ ...formData, prefix: value })
                        }
                      >
                        <Option value="+86">+86</Option>
                        <Option value="+1">+1</Option>
                      </Select>
                    </Col>
                    <Col span={16}>
                      <Input
                        name="phone"
                        placeholder={t('phoneLabel')}
                        onChange={handleChange}
                        value={formData.phone}
                      />
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  label={t('smsLabel')}
                  name="verificationCode"
                  rules={[
                    {
                      required: true,
                      message: `${t('smsLabel')} is required`
                    }
                  ]}
                >
                  <Row gutter={8}>
                    <Col span={16}>
                      <Input
                        name="verificationCode"
                        onChange={handleChange}
                        value={formData.verificationCode}
                      />
                    </Col>
                    <Col span={8}>
                      <Button
                        onClick={handleSendCode}
                        disabled={disableCodeBtn}
                      >
                        {t('sendCodeBtn')}
                        {disableCodeBtn && ` (${countdown})`}
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  label={t('passwordLabel')}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `${t('passwordLabel')} is required`
                    }
                  ]}
                >
                  <Input.Password
                    name="password"
                    onChange={handleChange}
                    value={formData.password}
                  />
                </Form.Item>

                <Form.Item
                  label={t('confirmPasswordLabel')}
                  name="passwordConfirm"
                  rules={[
                    {
                      required: true,
                      message: `${t('confirmPasswordLabel')} is required`
                    }
                  ]}
                >
                  <Input.Password
                    name="passwordConfirm"
                    onChange={handleChange}
                    value={formData.passwordConfirm}
                  />
                </Form.Item>

                <Form.Item style={{ marginTop: 20 }}>
                  <Button
                    type="primary"
                    onClick={handleSignup}
                    style={{ marginRight: 8 }}
                  >
                    {t('signupBtn')}
                  </Button>
                  <Button onClick={handleNavigateToLogin}>
                    {t('haveAccountBtn')}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default SignupForm;
