import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Input,
  Button,
  Select,
  notification,
  Row,
  Col,
  Layout,
  Typography
} from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const { Content } = Layout;
const { Title } = Typography;

// Translations for English (en) and Chinese (zh):
const translations = {
  en: {
    loginTitle: 'Login',
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    loginBtn: 'Login',
    phoneLoginBtn: 'Login with Phone',
    noAccountBtn: 'Sign Up',
    phoneLabel: 'Phone',
    smsLabel: 'Verification Code',
    sendSMSBtn: 'Get SMS Code',
    backToPasswordBtn: 'Back to Password',
    loginFailedMsg: 'Login Failed',
    invalidCredentials: 'Invalid username or password',
    invalidPhoneCredentials: 'Invalid phone number or verification code',
    languageBtn: '中文'
  },
  zh: {
    loginTitle: '登录',
    usernameLabel: '用户名',
    passwordLabel: '密码',
    loginBtn: '登录',
    phoneLoginBtn: '手机登录',
    noAccountBtn: '去注册',
    phoneLabel: '手机号',
    smsLabel: '验证码',
    sendSMSBtn: '获取验证码',
    backToPasswordBtn: '返回密码登录',
    loginFailedMsg: '登录失败',
    invalidCredentials: '用户名或密码不正确',
    invalidPhoneCredentials: '手机号或验证码不正确',
    languageBtn: 'English'
  }
};

const LoginForm = () => {
  const [lang, setLang] = useState('en'); // language state
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  // Username/Password states
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Phone login states
  const [phone, setPhone] = useState('');
  const [prefix, setPrefix] = useState('+86');
  const [verificationCode, setVerificationCode] = useState('');

  // SMS code button states
  const [disableCodeBtn, setDisableCodeBtn] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const navigate = useNavigate();

  // Translation helper
  const t = (key) => translations[lang][key];

  // Countdown effect for the "Send Code" button
  useEffect(() => {
    let timer;
    if (disableCodeBtn) {
      timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setDisableCodeBtn(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [disableCodeBtn]);

  // Toggle language
  const toggleLanguage = () => {
    setLang((prev) => (prev === 'en' ? 'zh' : 'en'));
  };

  // Handle standard login (username/password)
  const handleLogin = () => {
    axios
      .post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/login/`, {
        username,
        password
      })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          navigate('/dashboard');
        }
      })
      .catch(() => {
        notification.error({
          message: t('loginFailedMsg'),
          description: t('invalidCredentials')
        });
      });
  };

  // Handle phone login
  const handlePhoneLogin = () => {
    axios
      .post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/login2/`, {
        phone_number: prefix + phone,
        verification_code: verificationCode
      })
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          navigate('/dashboard');
        }
      })
      .catch(() => {
        notification.error({
          message: t('loginFailedMsg'),
          description: t('invalidPhoneCredentials')
        });
      });
  };

  // Send verification code
  const handleSendCode = () => {
    const phone_number = prefix + phone;
    axios
      .post(`${process.env.REACT_APP_API_ROOT_URL}/accounts/sendcode/`, {
        phone_number
      })
      .then((res) => {
        if (res.status === 200) {
          setDisableCodeBtn(true);
        }
      })
      .catch(() => {
        notification.error({
          message: t('loginFailedMsg'),
          description: t('invalidPhoneCredentials')
        });
      });
  };

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <Content>
        <Row
          justify="center"
          align="middle"
          style={{ minHeight: '100vh', padding: '20px' }}
        >
          <Col xs={24} sm={16} md={12} lg={8}>
            <Card
              title={
                <Row justify="space-between" align="middle">
                  <Title level={3} style={{ margin: 0 }}>
                    {t('loginTitle')}
                  </Title>
                  <Button type="link" onClick={toggleLanguage}>
                    {t('languageBtn')}
                  </Button>
                </Row>
              }
            >
              {/* Standard Login Form */}
              {!isPhoneLogin ? (
                <Form layout="vertical">
                  <Form.Item
                    label={t('usernameLabel')}
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: `${t('usernameLabel')} is required`
                      }
                    ]}
                  >
                    <Input
                      onChange={(e) => setUsername(e.target.value)}
                      value={username}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('passwordLabel')}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: `${t('passwordLabel')} is required`
                      }
                    ]}
                  >
                    <Input.Password
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                  </Form.Item>

                  <Form.Item style={{ marginTop: 16 }}>
                    <Button
                      type="primary"
                      onClick={handleLogin}
                      style={{ marginRight: 8 }}
                    >
                      {t('loginBtn')}
                    </Button>
                    <Button
                      type="link"
                      onClick={() => setIsPhoneLogin(true)}
                      style={{ marginRight: 8 }}
                    >
                      {t('phoneLoginBtn')}
                    </Button>
                    <Button type="link" onClick={() => navigate('/signup')}>
                      {t('noAccountBtn')}
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                // Phone Login Form
                <Form layout="vertical">
                  <Form.Item
                    label={t('phoneLabel')}
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: `${t('phoneLabel')} is required`
                      }
                    ]}
                  >
                    <Row gutter={8}>
                      <Col span={8}>
                        <Select
                          defaultValue="+86"
                          style={{ width: '100%' }}
                          onChange={(value) => setPrefix(value)}
                        >
                          <Option value="+86">+86</Option>
                          <Option value="+1">+1</Option>
                          {/* Add more country codes as needed */}
                        </Select>
                      </Col>
                      <Col span={16}>
                        <Input
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder={t('phoneLabel')}
                          value={phone}
                        />
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item
                    label={t('smsLabel')}
                    name="verificationCode"
                    rules={[
                      {
                        required: true,
                        message: `${t('smsLabel')} is required`
                      }
                    ]}
                  >
                    <Row gutter={8}>
                      <Col span={16}>
                        <Input
                          onChange={(e) => setVerificationCode(e.target.value)}
                          value={verificationCode}
                        />
                      </Col>
                      <Col span={8}>
                        <Button
                          onClick={handleSendCode}
                          disabled={disableCodeBtn}
                        >
                          {t('sendSMSBtn')}
                          {disableCodeBtn && ` (${countdown})`}
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item style={{ marginTop: 16 }}>
                    <Button
                      type="primary"
                      onClick={handlePhoneLogin}
                      style={{ marginRight: 8 }}
                    >
                      {t('loginBtn')}
                    </Button>
                    <Button
                      type="link"
                      onClick={() => setIsPhoneLogin(false)}
                      style={{ marginRight: 8 }}
                    >
                      {t('backToPasswordBtn')}
                    </Button>
                    <Button type="link" onClick={() => navigate('/signup')}>
                      {t('noAccountBtn')}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginForm;
