import React, { useEffect, useState } from 'react';
import {
  Layout,
  Row,
  Col,
  Card,
  Descriptions,
  Space,
  Divider,
  Button,
  Modal,
  List,
  notification
} from 'antd';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// For quick reference
const { Header, Content, Footer } = Layout;

/* -------------------------------------------------------------------------
   1. Define translations for both English (en) and Chinese (zh).
      You can expand these to translate more text if needed.
------------------------------------------------------------------------- */
const translations = {
  en: {
    // Headers / Titles
    headerTitle: 'Dashboard',
    userInfoTitle: 'User Information',
    serviceChoiceTitle: 'Choose a Service',
    logoutLabel: 'Log Out',
    logoutConfirmTitle: 'Confirm Logout',
    logoutConfirmText: 'Are you sure you want to log out?',
    cancelBtn: 'Cancel',
    okBtn: 'OK',

    // Labels
    username: 'Username',
    phone: 'Phone',
    wallet: 'Wallet (¥)',
    
    // Services
    chatService: 'ChatGPT Chat Service',
    dalleService: 'Dall-E Image Generation',

    // Buttons / Misc
    alipayBtn: 'Recharge with Alipay',
    wechatpayBtn: 'Recharge with WeChatPay',
    paymentRecordBtn: 'View Payment Records',

    // Prices
    chatPrice: '0.01 CNY per request',
    dallePrice: '0.2 CNY per request',

    // Language
    languageBtn: '中文'
  },
  zh: {
    // Headers / Titles
    headerTitle: '控制面板',
    userInfoTitle: '用户信息',
    serviceChoiceTitle: '选择服务',
    logoutLabel: '退出当前账号',
    logoutConfirmTitle: '确认退出',
    logoutConfirmText: '你确定退出当前账号吗？',
    cancelBtn: '取消',
    okBtn: '确定',

    // Labels
    username: '用户名',
    phone: '电话',
    wallet: '余额 (¥)',
    
    // Services
    chatService: 'ChatGPT 对话服务',
    dalleService: 'Dall-E 图片生成',

    // Buttons / Misc
    alipayBtn: '支付宝充值',
    wechatpayBtn: '微信支付充值',
    paymentRecordBtn: '查看支付记录',

    // Prices
    chatPrice: '¥0.01 / 每次请求',
    dallePrice: '¥0.2 / 每次请求',

    // Language
    languageBtn: 'English'
  }
};

const ProfileDashboard = () => {
  const navigate = useNavigate();

  // ------------------------------
  // 2. Language Toggle State
  // ------------------------------
  const [lang, setLang] = useState('en'); // 'en' or 'zh'
  const t = (key) => translations[lang][key];

  // ------------------------------
  // 3. Dashboard State
  // ------------------------------
  const [user, setUser] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  // ------------------------------
  // 4. Fetch User Data (on mount)
  // ------------------------------
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios
      .get(`${process.env.REACT_APP_API_ROOT_URL}/accounts/profile/`, {
        headers: { Authorization: `Token ${token}` }
      })
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      });
  }, [navigate]);

  // ------------------------------
  // 5. Logout Flow
  // ------------------------------
  const handleLogout = () => {
    setIsModalVisible(true);
  };

  const confirmLogout = () => {
    const token = localStorage.getItem('token');
    axios
      .post(
        `${process.env.REACT_APP_API_ROOT_URL}/accounts/logout/`,
        {},
        { headers: { Authorization: `Token ${token}` } }
      )
      .then(() => {
        localStorage.removeItem('token');
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error logging out:', error);
        notification.error({
          message: 'Error',
          description: 'Failed to log out. Please try again.'
        });
      });
  };

  // ------------------------------
  // 6. Service Navigation
  // ------------------------------
  const handleServiceClick = (path) => {
    navigate(path);
  };

  // 7. Toggle Language
  const toggleLanguage = () => {
    setLang((prev) => (prev === 'en' ? 'zh' : 'en'));
  };

  // 8. Service Data
  const services = [
    {
      label: t('chatService'),
      path: '/chat',
      price: t('chatPrice')
    },
    {
      label: t('dalleService'),
      path: '/dalle',
      price: t('dallePrice')
    }
    // Add more services as needed...
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* -------------------------------------------
          HEADER
      ------------------------------------------- */}
      <Header style={{ background: '#001529', padding: '0 20px' }}>
        <Row justify="space-between" align="middle" style={{ height: '64px' }}>
          <Col>
            <h2 style={{ color: '#fff', margin: 0 }}>{t('headerTitle')}</h2>
          </Col>
          <Col>
            <Button type="primary" onClick={toggleLanguage}>
              {t('languageBtn')}
            </Button>
          </Col>
        </Row>
      </Header>

      {/* -------------------------------------------
          CONTENT
      ------------------------------------------- */}
      <Content style={{ padding: '24px', backgroundColor: '#f0f2f5' }}>
        <Row justify="center" gutter={[16, 16]}>
          <Col xs={24} md={16} lg={12}>
            {/* User Info */}
            <Card
              title={t('userInfoTitle')}
              bordered
              style={{ marginBottom: '20px' }}
            >
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label={t('username')}>
                  {user.username}
                </Descriptions.Item>
                <Descriptions.Item label={t('phone')}>
                  {user.phone_number}
                </Descriptions.Item>
                <Descriptions.Item label={t('wallet')}>
                  {user.wallet_balance}
                </Descriptions.Item>
                {/* Add more fields as needed */}
              </Descriptions>
            </Card>

            {/* Services */}
            <Card title={t('serviceChoiceTitle')} style={{ marginBottom: 20 }}>
              <List
                bordered
                dataSource={services}
                renderItem={(item) => (
                  <List.Item
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleServiceClick(item.path)}
                  >
                    <Space
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <span>{item.label}</span>
                      <span style={{ color: '#888' }}>{item.price}</span>
                    </Space>
                  </List.Item>
                )}
              />
            </Card>

            {/* Actions */}
            <Card>
              <Space direction="vertical" style={{ width: '100%' }}>
                <Button type="default" onClick={() => navigate('/alipay')}>
                  {t('alipayBtn')}
                </Button>
                <Button type="default" onClick={() => navigate('/wechatpay')}>
                  {t('wechatpayBtn')}
                </Button>
                <Button type="default" onClick={() => navigate('/payment-record')}>
                  {t('paymentRecordBtn')}
                </Button>
                <Divider />
                <Button type="primary" danger onClick={handleLogout}>
                  {t('logoutLabel')}
                </Button>
              </Space>
            </Card>
          </Col>
        </Row>
      </Content>

      {/* -------------------------------------------
          FOOTER
      ------------------------------------------- */}
      <Footer style={{ textAlign: 'center' }}>
        © 2025 Yuantsy Group. All rights reserved.
      </Footer>

      {/* -------------------------------------------
          LOGOUT MODAL
      ------------------------------------------- */}
      <Modal
        title={t('logoutConfirmTitle')}
        visible={isModalVisible}
        onOk={confirmLogout}
        onCancel={() => setIsModalVisible(false)}
        okText={t('okBtn')}
        cancelText={t('cancelBtn')}
      >
        <p>{t('logoutConfirmText')}</p>
      </Modal>
    </Layout>
  );
};

export default ProfileDashboard;
