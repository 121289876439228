import React, { useState, useEffect } from 'react';
import { List, Input, Button, Layout, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

function Chat() {
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([
    {
      role: 'system',
      content: '你好，这里是源子，有什么可以帮您？\nThis is Yuantsy, what can I help?'
    },
  ]);
  const navigate = useNavigate();

  // Responsive container
  const [containerStyle, setContainerStyle] = useState({
    maxWidth: 800,
    width: '100%',
    margin: '0 auto',
    padding: '16px',
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setContainerStyle(prev => ({
          ...prev,
          maxWidth: '96%',
        }));
      } else {
        setContainerStyle(prev => ({
          ...prev,
          maxWidth: 800,
        }));
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Styles
  const styles = {
    mainWrapper: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f0f2f5'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 24px',
      backgroundColor: '#001529',
    },
    headerTitle: {
      color: '#fff',
      margin: 0,
      fontSize: '1.25rem',
      flexGrow: 1
    },
    backButton: {
      marginLeft: 'auto',
      color: '#1890ff',
      border: '1px solid #1890ff',
    },
    content: {
      flex: 1,
      padding: '24px',
      display: 'flex',
      flexDirection: 'column',
    },
    messageList: {
      flex: 1,
      marginBottom: '16px',
      overflow: 'auto',
      borderRadius: '4px',
      backgroundColor: '#fff',
      padding: '16px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
    },
    listItem: {
      margin: '8px 0',
      padding: '8px 12px',
      borderRadius: '4px',
      backgroundColor: '#fafafa',
    },
    userTitle: {
      color: '#1890ff',
    },
    systemTitle: {
      color: '#722ed1',
    },
    contentText: {
      whiteSpace: 'pre-wrap',
      color: '#333'
    },
    inputArea: {
      display: 'flex',
      gap: '8px',
      marginTop: '16px',
    },
    inputTextArea: {
      flexGrow: 1,
      resize: 'none',
    },
    footer: {
      textAlign: 'center',
      backgroundColor: '#f0f2f5',
      padding: '16px',
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (inputValue.trim() === '') {
      return;
    }
    const token = localStorage.getItem('token');
    if (!token) {
      notification.error({
        message: 'Authentication Error',
        description: 'User not logged in. Redirecting to login page.'
      });
      navigate('/login');
      return;
    }

    // Add user's message
    setMessages(prev => [...prev, { role: 'user', content: inputValue }]);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ROOT_URL}/service/chatbot/`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
          message: inputValue,
          chatbot_messages: messages
        })
      });

      // Handle 400
      if (response.status === 400) {
        const errorData = await response.json();
        if (errorData.error === 'Insufficient balance.') {
          notification.error({
            message: 'Insufficient Balance',
            description: 'Please add funds to your wallet.'
          });
          return;
        } else if (errorData.error === 'Wallet not found for user.') {
          notification.error({
            message: 'Wallet Error',
            description: 'Wallet not found. Please contact support.'
          });
          return;
        }
      } 
      // Handle 401 & 403
      else if (response.status === 401 || response.status === 403) {
        let errorDescription = 'An error occurred. Please try again later.';
        if (response.status === 401) {
          errorDescription = 'Invalid token. Redirecting to login page.';
          localStorage.removeItem('token'); 
        } else if (response.status === 403) {
          errorDescription = 'Forbidden access. Redirecting to login page.';
        }
        notification.error({
          message: 'Error',
          description: errorDescription
        });
        navigate('/login');
      } 
      // Handle normal responses
      else {
        const data = await response.json();
        setMessages(prev => [...prev, { role: 'assistant', content: data.response }]);
      }
    } catch (error) {
      notification.error({
        message: 'Network Error',
        description: 'Failed to send message. Please check your network and try again.'
      });
    }

    setInputValue('');
  };

  const handleContent = (content) => {
    return content.split('\n').map((line, index) => (
      <p key={index} style={styles.contentText}>{line}</p>
    ));
  };

  return (
    <Layout style={styles.mainWrapper}>
      <Header style={styles.header}>
        <Title level={3} style={styles.headerTitle}>Chat with 源子</Title>
        <Button style={styles.backButton} onClick={() => navigate("/dashboard")}>
          返回主页
        </Button>
      </Header>

      <Content style={styles.content}>
        <div style={{ ...containerStyle }}>
          <div style={styles.messageList}>
            <List
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(message) => (
                <List.Item style={styles.listItem}>
                  <List.Item.Meta
                    title={
                      <span style={message.role === 'user' ? styles.userTitle : styles.systemTitle}>
                        {message.role === 'user' ? 'You' : '源子'}
                      </span>
                    }
                    description={handleContent(message.content)}
                  />
                </List.Item>
              )}
            />
          </div>
          <div style={styles.inputArea}>
            <Input.TextArea
              style={styles.inputTextArea}
              placeholder="在这里输入..."
              value={inputValue}
              onChange={handleInputChange}
              autoSize
            />
            <Button type="primary" onClick={handleSubmit}>发送</Button>
          </div>
        </div>
      </Content>

      <Footer style={styles.footer}>
        © 2023 源子 (Yuantsy)
      </Footer>
    </Layout>
  );
}

export default Chat;
